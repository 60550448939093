<template>
  <form name="form-support" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Send Support Request</p>
      </header>
      <section class="modal-card-body">
        <input type="hidden" name="form-name" value="form-support">

        <b-field label="Your Email">
          <b-input type="email" v-model="form.email" placeholder="Your email" required></b-input>
        </b-field>

        <b-field label="Your Enquiry">
          <b-input type="textarea" v-model="form.message" required></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="handleClose()">Close</button>
        <button class="button is-primary" @click.prevent="handleSubmit()">Send</button>
      </footer>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { Toast } from 'buefy/dist/components/toast'

export default {
  name: 'SupportForm',
  props: {
    email: String,
    message: String
  },
  data () {
    return {
      form: {
        email: '',
        message: ''
      }
    }
  },
  methods: {
    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
    },
    handleClose () {
      this.$parent.close()
    },
    handleSubmit () {
      if (!this.form.email) {
        Toast.open({
          message: 'Please enter your email',
          type: 'is-danger'
        })
        return
      }

      if (!this.form.message) {
        Toast.open({
          message: 'Please enter your message',
          type: 'is-danger'
        })

        return
      }

      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }
      axios
        .post(
          '/form_support',
          this.encode({
            'form-name': 'form-support',
            ...this.form
          }),
          axiosConfig
        )
        .then(() => {
          Toast.open({
            message: 'Thank you! Your form has been submitted',
            type: 'is-success'
          })
          this.handleClose()
        })
        .catch(() => {
          Toast.open({
            message:
              'Sorry for the inconvenience - an occurred when sending your submission. Could you please try emailing instead?',
            type: 'is-warning'
          })
        })
    }
  }
}
</script>
