<template>
  <div class="contact">
    <section id="hero-contact" class="hero hero-emphasis is-medium is-bold has-text-centered">
      <div class="hero-body">
        <div class="container">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div class="box">
                <div class="circle-icon">
                  <i class="far fa-lightbulb"></i>
                </div>
                <h3 class="is-size-5">Solutions</h3>
                <div>
                  <div class="field-email">Email:
                    <a
                      :href="'mailto:solutions' + emailSuffix + '?subject=Convergio - Solutions Enquiry'"
                    >metx{{emailSuffix}}</a>
                  </div>
                  <div>Call: (+61) 1300 864 343</div>
                  <div>Fax: (+61) 1300 864 344</div>
                </div>
                <br>
                <a class="button is-primary" @click="isSolutionsModalActive = true">Get in touch!</a>
              </div>
            </div>

            <div class="tile is-parent">
              <div class="box">
                <div class="circle-icon">
                  <i class="fas fa-file-invoice-dollar"></i>
                </div>
                <h3 class="is-size-5">Accounts</h3>
                <div>
                  <div class="field-email">Email:
                    <a
                      :href="'mailto:accounts' + emailSuffix + '?subject=Convergio - Accounts Enquiry'"
                    >metx{{emailSuffix}}</a>
                  </div>
                  <div>Call: (+61) 1300 864 343</div>
                  <div>Fax: (+61) 1300 864 344</div>
                </div>

                <br>
                <a class="button is-dark" @click="isAccountsModalActive = true">Accounts Details</a>
              </div>
            </div>

            <div class="tile is-parent">
              <div class="box">
                <div class="circle-icon">
                  <i class="fas fa-hands-helping"></i>
                </div>
                <h3 class="is-size-5">Support</h3>
                <div>
                  <div class="field-email">Email:
                    <a
                      :href="'mailto:support' + emailSuffix + '?subject=Convergio - Support Enquiry'"
                    >metx{{emailSuffix}}</a>
                  </div>
                  <div>Call: (+61) 1300 864 343</div>
                  <div>Fax: (+61) 1300 864 344</div>
                </div>

                <br>
                <a class="button is-dark" @click="isSupportModalActive = true">Get Support</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal :active.sync="isAccountsModalActive" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Convergio - Accounts Details</p>
        </header>
        <section class="modal-card-body">
          <table>
            <tr>
              <td>
                <strong>Company Name</strong>
              </td>
              <td>Convergio Pty Ltd</td>
            </tr>
            <tr>
              <td>
                <strong>Trading Name</strong>
              </td>
              <td>Convergio</td>
            </tr>
            <tr>
              <td>
                <strong>Postal Address</strong>
              </td>
              <td>PO Box 654, West Perth, Western Australia, 6872</td>
            </tr>
            <tr>
              <td>
                <strong>Registered Office</strong>
              </td>
              <td>16/1020 Wellington Street, West Perth, Western Australia, 6005</td>
            </tr>
            <tr>
              <td>
                <strong>ABN</strong>
              </td>
              <td>21 604 280 051</td>
            </tr>
            <tr>
              <td>
                <strong>ACN</strong>
              </td>
              <td>604 280 051</td>
            </tr>
          </table>
        </section>
        <footer class="modal-card-foot has-text-right">
          <button class="button" @click="isAccountsModalActive = false">Close</button>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="isSupportModalActive" has-modal-card>
      <support-form v-bind="supportFormProps"></support-form>
    </b-modal>

    <b-modal :active.sync="isSolutionsModalActive" has-modal-card>
      <solutions-form v-bind="solutionsFormProps"></solutions-form>
    </b-modal>
  </div>
</template>

<style>
#hero-contact {
  background-image: url("/img/banner-team.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}

table > tr > td:first-child {
  width: 150px;
  text-align: right;
  padding-right: 10px;
}

.modal-card-foot {
  justify-content: flex-end;
}

.modal-card {
  width: auto;
}

.field-email {
  min-width: 250px;
}

</style>

<script>
import SupportForm from '@/components/forms/Support.vue'
import SolutionsForm from '@/components/forms/Solutions.vue'

export default {
  components: {
    SupportForm,
    SolutionsForm
  },
  data () {
    return {
      emailSuffix: '',
      isSupportModalActive: false,
      isAccountsModalActive: false,
      isSolutionsModalActive: false,
      supportFormProps: {
        email: ''
      },
      solutionsFormProps: {
        email: ''
      }
    }
  },
  created: function () {
    setTimeout(() => {
      this.emailSuffix = '@convergio.com'
    }, 100)
  }
}
</script>
